import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  eventsTargets: HTMLElement[];
  navLinksTargets: HTMLAnchorElement[];

  static get targets() {
    return ["events", "navLinks"];
  }

  connect() {
    this.showYear(this.navLinksTargets[0].dataset.year);
  }

  navigate(evt: Event) {
    const target = evt.target as HTMLElement;
    evt.preventDefault();
    if ("dataset" in target && "year" in target.dataset) {
      this.showYear(target.dataset.year);
    }
  }

  showYear(year: string) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    this.navLinksTargets.forEach((n) => {
      if (n.dataset.year == year) {
        n.classList.add("current");
      } else {
        n.classList.remove("current");
      }
    });

    this.eventsTargets.forEach((e) => {
      const timestamp = new Date(e.dataset["last-date"]);

      if (year === "upcoming" && timestamp >= today) {
        e.classList.remove("hidden");
      } else if (year == e.dataset.year) {
        e.classList.remove("hidden");
      } else {
        e.classList.add("hidden");
      }
    });
  }
}
